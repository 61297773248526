<template>
  <div class="w-100">
    <div class="overflow-auto" style="max-height: 400px">
      <div class="d-flex justify-content-start py-2">
        <h5>인증 이력</h5>
      </div>
      <div v-if="pending">
        <b-spinner variant="danger" label="LOADING" />
      </div>
      <div v-else>
        <div class="d-flex justify-content-end mb-2">
          <b-form-select
              v-model="selected"
              :options="selectOption"
              v-on:change="load"
          />
        </div>
        <div>
          <b-table-simple v-if="selected === 'sms'">
            <colgroup>
              <col width="20%">
              <col>
              <col>
            </colgroup>
            <b-thead>
              <b-tr>
                <b-th scope="col">
                  일자
                </b-th>
                <b-th scope="col">
                  이름
                </b-th>
                <b-th scope="col">
                  생년월일
                </b-th>
                <b-th scope="col">
                  휴대폰 번호
                </b-th>
                <b-th scope="col">
                  상태
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                  v-for="h in auth" :key="`auth-history-${h._id}`"
              >
                <b-td>
                  {{ new Date(h.regDate).toLocaleString() }}
                </b-td>
                <b-td>
                  {{ h.data ? h.data.payment_data.name : ''}}
                </b-td>
                <b-td>
                  {{ h.data ? h.data.payment_data.birth : ''}}
                </b-td>
                <b-td>
                  {{ h.phone }}
                </b-td>
                <b-td>
                  {{ h.message }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-table-simple v-else>
            <colgroup>
              <col width="20%">
              <col>
              <col>
            </colgroup>
            <b-thead>
              <b-tr>
                <b-th scope="col">
                  일자
                </b-th>
                <b-th scope="col">
                  이메일
                </b-th>
                <b-th scope="col">
                  상태
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                  v-for="h in email" :key="`email-history-${h._id}`"
              >
                <b-td>
                  {{ new Date(h.verifyDate).toLocaleString() }}
                </b-td>
                <b-td>
                  {{ h.userEmail }}
                </b-td>
                <b-td>
                  {{ h.emailVerify ? `인증 성공` : `인증 안함` }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "auth",
  props: {
    selectedId: String
  },
  data: () => ({
    pending: false,
    auth: [],
    email: [],
    selected: null,
    selectOption: [{text: '본인인증', value: 'sms'}, {text: '메일인증', value: 'mail'}]
  }),
  created () {
    (async () => {
      this.selected = 'sms'
      await this.load()
    }) ()
  },
  methods: {
    async load () {
      this.pending = true
      try {
        const url = `/admin/users/auth-history/${this.selectedId}`
        const { data } = await this.axios({
          url,
          params: {
            selected: this.selected
          }
        })

        const { result, auth, email, error } = data
        if (result) {
          this.auth = auth
          this.email = email
        }
      } catch (e) {
      }
      this.pending = false
    }
  }
}
</script>

<style scoped>
th, td {
  font-size: 0.8rem;
  vertical-align: middle;
}
</style>
